import React from "react"
import Img from "gatsby-image"

const MiniWorkCard = ({ data }) => {
  return (
    <article className="mini-work-card">
      <a
        target="_blank"
        aria-label={data.frontmatter.title}
        rel="noopener noreferrer"
        href={data.frontmatter.slug}
      >
        {data.frontmatter.featuredImage ? (
          <Img
            fluid={data.frontmatter.featuredImage.childImageSharp.fluid}
            objectFit="cover"
            objectPosition="50% 50%"
            alt={data.frontmatter.title + " - Featured image"}
            className="other-works featured-image"
          />
        ) : (
          ""
        )}
        <div className="content">
          <p className="title">{data.frontmatter.title}</p>
        </div>
      </a>
    </article>
  )
}

export default MiniWorkCard
