import React from "react"
import { Link, graphql } from "gatsby"
import { RiArrowRightLine, RiArrowLeftLine } from "react-icons/ri"

import Layout from "../components/layout"
import SEO from "../components/seo"
import MiniWorkCard from "../components/mini-work-card"

export const otherWorksListQuery = graphql`
  query otherWorksListQuery($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { type: { eq: "mini-works" } } }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            slug
            title
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 960, maxHeight: 720, quality: 80) {
                  ...GatsbyImageSharpFluid
                  ...GatsbyImageSharpFluidLimitPresentationSize
                }
              }
            }
          }
        }
      }
    }
  }
`
const Pagination = props => (
  <div className="pagination">
    <ul>
      {!props.isFirst && (
        <li>
          <Link to={props.prevPage} rel="prev">
            <span className="icon -left">
              <RiArrowLeftLine />
            </span>{" "}
            Previous
          </Link>
        </li>
      )}
      {Array.from({ length: props.numPages }, (_, i) => (
        <li key={`pagination-number${i + 1}`}>
          <Link
            to={`${props.blogSlug}${i === 0 ? "" : i + 1}`}
            className={props.currentPage === i + 1 ? "is-active num" : "num"}
          >
            {i + 1}
          </Link>
        </li>
      ))}
      {!props.isLast && (
        <li>
          <Link to={props.nextPage} rel="next">
            Next{" "}
            <span className="icon -right">
              <RiArrowRightLine />
            </span>
          </Link>
        </li>
      )}
    </ul>
  </div>
)

const OtherWorks = ({ data, pageContext }) => {
  const { currentPage, numPages } = pageContext
  const blogSlug = "/other-works/"
  const isFirst = currentPage === 1
  const isLast = currentPage === numPages
  const prevPage =
    currentPage - 1 === 1 ? blogSlug : blogSlug + (currentPage - 1).toString()
  const nextPage = blogSlug + (currentPage + 1).toString()

  const posts = data.allMarkdownRemark.edges
    .filter(edge => !!edge.node.frontmatter.date)
    .map(edge => <MiniWorkCard key={edge.node.id} data={edge.node} />)

  let props = {
    isFirst,
    prevPage,
    numPages,
    blogSlug,
    currentPage,
    isLast,
    nextPage,
  }

  return (
    <Layout className="blog-page">
      <SEO
        title={"Blog Post — Page " + currentPage + " of " + numPages}
        description={"Kikxle base blog page " + currentPage + " of " + numPages}
      />
      <div className="wrapper">
        <h1 className="page-title">
          <Link to="/blog">Blog Post</Link>
          <Link className="active" to="/other-works">
            Mini Works
          </Link>
        </h1>
        <hr />
        <div className="grids col-1 sm-2 md-2">{posts}</div>
      </div>
      <Pagination {...props} />
    </Layout>
  )
}

export default OtherWorks
